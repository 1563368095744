import { useState } from "react";
import Footer from "../footer/footer.component";
import Header from "../header/header.component";
import Main from "../main/main.component";
import Overlay from "../overlay/overlay.component";

const MainPage = () => {
  const [isOverlayVisible, setOverlay] = useState<boolean>(false);
  return (
    <>
      <Header />
      <Main />
      <Footer setOverlay={setOverlay} />
      <Overlay isVisible={isOverlayVisible} />
    </>
  );
};

export default MainPage;
