import { FC, useEffect, useState } from "react";
import css from "./footer.module.css";
import Button from "../button/button.component";
import cn from "classnames";
import LoaderIcon from "../icons/loader-icon.component";
import SuccesIcon from "../icons/succes-icon.component";
import FalledIcon from "../icons/falled-icon.component";
import OverdueIcon from "../icons/overdue-icon.component";
import CountdownTimer from "../countdownTimer/countdownTimer.component";

type FooterType = "regular" | "pending" | "success" | "falled" | "overdue";
interface FooterProps {
  setOverlay: React.Dispatch<React.SetStateAction<boolean>>;
}
const Footer: FC<FooterProps> = ({ setOverlay }) => {
  const [type, setType] = useState<FooterType>("regular");

  const footerClass = cn(css.wrap, {
    [css.regular]: type === "regular",
    [css.big]: type !== "regular",
    [css.pending]: type === "pending",
    [css.success]: type === "success",
    [css.falled]: type === "falled",
    [css.overdue]: type === "overdue",
  });

  useEffect(() => {
    if (type !== "regular") {
      setOverlay(true);
    } else {
      setOverlay(false);
    }
  }, [type]);

  const renderFooterContent = () => {
    switch (type) {
      case "regular":
        return (
          <>
            <div className={css.timeWrap}>
              <span className={css.title}>До закрытия сделки</span>
              <CountdownTimer initialTime={"16:30"} />
            </div>

            <Button
              value="Продолжить"
              onClick={() => setType("success")}
              color="green"
            />
          </>
        );
      case "pending":
        return (
          <>
            <div className={css.loaderWrap}>
              <LoaderIcon />
            </div>
            <span> Ожидаем оплату</span>
          </>
        );
      case "success":
        return (
          <>
            <SuccesIcon />
            <span className={css.description}>Успешная оплата</span>
            <Button
              onClick={() => setType("regular")}
              value={"Вернуться"}
              color={"white"}
            />
          </>
        );
      case "falled":
        return (
          <>
            <FalledIcon />
            <span className={css.description}>Платеж не прошел</span>
            <Button
              onClick={() => setType("regular")}
              value={"Повторить платеж"}
              color={"black"}
            />
          </>
        );
      case "overdue":
        return (
          <>
            <OverdueIcon />
            <span className={css.description}>Истек срок сделки</span>
            <Button
              onClick={() => setType("regular")}
              value={"Повторить платеж"}
              color={"black"}
            />
          </>
        );
      default:
        return null;
    }
  };
  return <footer className={footerClass}>{renderFooterContent()}</footer>;
};
export default Footer;
