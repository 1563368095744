const CopyIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.4" clip-path="url(#clip0_1_24)">
        <path
          d="M9.33337 12.8893C9.33337 11.9462 9.70802 11.0417 10.3749 10.3749C11.0418 9.70798 11.9463 9.33333 12.8894 9.33333H24.444C24.911 9.33333 25.3734 9.42531 25.8049 9.60401C26.2363 9.78272 26.6283 10.0447 26.9585 10.3749C27.2887 10.7051 27.5507 11.0971 27.7294 11.5285C27.9081 11.9599 28 12.4223 28 12.8893V24.444C28 24.911 27.9081 25.3734 27.7294 25.8048C27.5507 26.2363 27.2887 26.6283 26.9585 26.9585C26.6283 27.2887 26.2363 27.5506 25.8049 27.7293C25.3734 27.908 24.911 28 24.444 28H12.8894C12.4224 28 11.96 27.908 11.5286 27.7293C11.0971 27.5506 10.7051 27.2887 10.3749 26.9585C10.0447 26.6283 9.78276 26.2363 9.60406 25.8048C9.42535 25.3734 9.33337 24.911 9.33337 24.444V12.8893Z"
          stroke="#232323"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M5.34933 22.316C4.94046 22.0829 4.60036 21.746 4.36343 21.3393C4.1265 20.9327 4.00113 20.4707 4 20V6.66667C4 5.2 5.2 4 6.66667 4H20C21 4 21.544 4.51333 22 5.33333"
          stroke="#232323"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_24">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CopyIcon;
