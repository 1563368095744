import { FC } from "react";

const OverdueIcon: FC = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_310)">
        <path
          d="M69.9901 40.8333C70.1575 34.8271 68.5174 28.9089 65.2823 23.8456C62.0473 18.7822 57.3663 14.807 51.8459 12.4347C46.3254 10.0625 40.2198 9.40267 34.3199 10.5407C28.4201 11.6786 22.9979 14.562 18.7557 18.8173C14.5136 23.0726 11.647 28.5037 10.5273 34.407C9.40752 40.3103 10.0863 46.4139 12.4755 51.927C14.8648 57.4401 18.8545 62.1088 23.9279 65.3281C29.0012 68.5475 34.9244 70.1693 40.9301 69.9833"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M53.3333 63.3333C53.3333 65.9855 54.3868 68.529 56.2622 70.4044C58.1375 72.2798 60.6811 73.3333 63.3333 73.3333C65.9854 73.3333 68.529 72.2798 70.4043 70.4044C72.2797 68.529 73.3333 65.9855 73.3333 63.3333C73.3333 60.6812 72.2797 58.1376 70.4043 56.2623C68.529 54.3869 65.9854 53.3333 63.3333 53.3333C60.6811 53.3333 58.1375 54.3869 56.2622 56.2623C54.3868 58.1376 53.3333 60.6812 53.3333 63.3333Z"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M56.6667 70L70.0001 56.6667"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40 23.3333V40L46.6667 46.6667"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_310">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default OverdueIcon;
