import React, { FC } from "react";
import css from "./header.module.css";

const Header: FC = () => {
  return (
    <header className={css.header}>
      <div className={css.textWrapper}>
        <p className={css.title}>НОМЕР ЗАКАЗА</p>
        <p className={css.info}>#89199341</p>
      </div>
      <div className={css.textWrapper}>
        <p className={css.title}>ОПЛАТА</p>
        <p className={css.info}>732,94 USDT</p>
      </div>
    </header>
  );
};

export default Header;
