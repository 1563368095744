import { FC } from "react";

const LoaderIcon: FC = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1_93)">
        <path
          d="M40 20V10"
          stroke="#232323"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.1"
          d="M54.1666 25.8333L61.3333 18.6667"
          stroke="#232323"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.3"
          d="M60 40H70"
          stroke="#232323"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.5"
          d="M54.1666 54.1667L61.3333 61.3333"
          stroke="#232323"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.7"
          d="M40 60V70"
          stroke="#232323"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          opacity="0.9"
          d="M25.8333 54.1667L18.6666 61.3333"
          stroke="#232323"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20 40H10"
          stroke="#232323"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M25.8333 25.8333L18.6666 18.6667"
          stroke="#232323"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_93">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LoaderIcon;
